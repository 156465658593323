<template>
  <div class="page">
    <NavBar />
    <div class="about container">
      <section class="section about__content">
        <h1 class="about__title">A PROPOS</h1>
        <p class="about__text">
          Développeur web basé à Paris, je vous accompagne dans la mise en place
          de nouveaux outils pour votre plateforme, mais aussi dans le création
          de site complet pour votre entreprise (portfolio, wordpress, vitrine).
          <span class="br" />Mes compétences sont variées, allant de html, css,
          javascript jusqu’à php, en passant par Vue.js, Node.js, Firebase.
          <span />
          <span class="br"
            >Entre mes missions, je pratique l’escalade, le vélo, et je modélise
            et imprime des pièces en 3d.</span
          >
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `A Propos`,
      meta: [
        {
          name: "About",
          content: `Développeur web basé à Paris, je vous accompagne dans la mise en place
          de nouveaux outils pour votre plateforme, mais aussi dans le création
          de site complet pour votre entreprise.`
        }
      ]
    };
  }
};
</script>
